/* admin */

div.header {
  border-bottom: 1px solid var(--black);
}

img.headerLogo {
  width: 4em;
}

/* menu */

div.menu {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0 0 1em 0;
}

div.item {
  display: flex;
  flex-direction: column;
}

div.searchButtons > a {
  padding: .2em
}

/* customers */

div.customers {
  flex: 1;
}

table.customers {
  margin: auto;
}

table.customers td {
  padding: 1em;
}

table.customers th {
  background-color: var(--grey);
  padding: 1em;
}

table.customers tr:nth-child(even) {
    background-color: var(--grey);
}

th.sortable:hover {
  cursor: pointer;
  opacity: .5;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-left: .4em;
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.hover:hover {
  cursor: pointer;
}

/* customer */
div.customer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0 1em;
}

div.status {
  font-weight: bold;
  color: var(--white);
  background-color: var(--green);
  display: block;
  margin: 0 0.5em;
  padding: 0.5em; 
}

div.customer input {
  margin: 0 0 1em 0;
}

div.update > button {
  display: block;
  width: 14em;
  margin: 1em auto;
}

tr.additionalEmail {
  text-align: left;
}

button.delete {
  background-color: var(--red);
}

.suggestion {
  color: var(--red);
}

.suggestion:hover{
  cursor: pointer;
}

/* productKeys */
div.keys > table {
  margin: auto;
}

button.revoke {
  background-color: var(--red);
}

button.unrevoke {
  background-color: var(--green);
}

.productKeys > table {
  table-layout: fixed;
}

.productKeys > table td {
  max-width: 20em;
}

.productKeys table tr:nth-child(2n) {
  background-color: var(--grey);
}

.productDesc {
  text-align: center;
  font-size: 0.9em;
  font-weight: bold;
}

a.revoked {
  color: var(--red);
  font-style: italic;
}

tr.activation {
  background-color: var(--grey);
}

tr.activation > td {
  padding: .2em .6em;
}

/* orders */
.orders > table {
  margin: auto;
}

.orders table > tr:nth-child(2n) {
  background-color: var(--grey);
}

.orders table th {
  padding: 0 .4em;
}

td a,
.orders a {
  text-decoration: none;
  color: #000;
}

td a:hover,
.orders a:hover {
  text-decoration: underline;
}


/* media queries */
@media screen and (max-width: 1024px) {
  div.customer {
    flex-direction: column;
  }
}
