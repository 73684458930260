/* Customer */

img.headerLogo {
  width: 4em;
}

.instructions {
  font-family: courier;
}


/* product keys */

table.productKey {
  margin: auto;
  width: 100%;
}

table.productKey td {
  padding: 1em;
}

td.features{
  width: 15%;
}

td.activations,td.coupons {
  width: 17.5%;
}

td.download,td.version {
  width: 10%;
}

td.productKey {
  width: 30%;
}

div.productKeyProper {
  font-weight: bold;
}

div.productKeyProper:hover {
  cursor: pointer;
  opacity: .5;
}

.download {
  font-family: "PT Mono Bold", "courier";
}

img.download {
  width: 20px;
}

img.download:hover {
  cursor: pointer;
  opacity: .5;
}

ul.features {
  text-align:left;
  font-size: .8em;
}

ul.features .productName {
  font-weight: bold;
  display: block;
}

ul.downloads {
  list-style-type: none;
  text-align: center;
  font-size: .8em;
  padding: 0.5em;
  margin: 0.5em;
}

ul.downloads li {
  padding: 0.5em
}

div.productKeyProper {
  margin: 1em;
}

div.activation {
  margin: 1em;
}

div.activationDetails {
  font-size: .8em;
}

div.activationDetails > span {
  padding: 0 .5em;
}

/* invoices */

table.orders {
  margin: auto;
}
table.orders tbody tr td {
  padding: 0 1em;
}

.bold {
  font-weight: 800;
}

/* invoice */

div.row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

div.row>div {
  width: auto;
}

div.seller {
  text-align: left;
}

table.products {
  margin-top: 15px;
  border-spacing: 0;
}

table.products th {
  padding: 1em 0;
}

table.products td {
  border-top: 1px solid black;
  padding: 1em 2em;
}

input.buyer {
  display: block;
}

small.editBuyer:hover {
  cursor: pointer;
  opacity: .5;
}

.buyer {
  text-align: left;
}

.footerLink {
  font-family: "PT Mono Bold", "courier";
  margin: 15px 0;
  padding-top: 15px;
  border-top: 1px solid black;
}

.footerLink a, .productKey a {
  text-decoration: none;
  color: #000;
}

.footerLink a:hover, .productKey a:hover {
  text-decoration: underline;
}
