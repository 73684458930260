/* vars */

body {
  --grey: #eee;
  --gold: #DEAC4E;
  --black: #000;
  --white: #fff;
  --red: #e44;
  --green: rgb(1, 76, 1);
}

.App {
  text-align: center;
}

button {
  border: none;
  border-radius: 4px;
  background-color: var(--black);
  color: var(--white);
  padding: .6em;
}

button:hover {
  cursor: pointer;
  opacity: .5;
}

button:disabled {
    opacity: .5;
    cursor: auto;
}

input {
  padding: .4em
}

.main {
  min-height: calc(100vh - 70px);
}

span.paginationControl:hover {
  cursor: pointer;
  opacity: .5;
}

span.paginationControl {
  padding: .5em;
}

h5.paginationHeader {
  margin: 0 auto;
}

/*
.footer {
  border-top: solid 1px var(--black);
  padding-top: 2em;
  height: 70px;
}
*/
.footer {
  height: 30px;
	/* position: fixed; */
	bottom: 0;
	text-align: center;
	width: 100%;
	padding-top: 20px;
	padding-bottom: 20px;
	border-top: 1px solid black;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-backdrop-filter: blur(3px);
	backdrop-filter: blur(3px);
	background-color: rgba(255, 255, 255, 0.8);
  display:flex;
  justify-content:center;
  align-items:center;

}

.footer a {
	color: black;
	text-decoration: none;
}

.footer a:hover {
	border-bottom: 1px dotted black;
}
